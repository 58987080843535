import { createRef, Component } from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react';
import { action, computed, observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import map from 'lodash.map';
import uniq from 'lodash.uniq';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import { ChevronSmallRight} from '@styled-icons/entypo/ChevronSmallRight';
import { Warning } from '@styled-icons/entypo/Warning';
import { New } from '@styled-icons/entypo/New';
import Link from 'next/link';
import { useTranslations } from 'next-intl';

import Loader from '@/components/loader';
import { colors, media } from '@/themes';
import Card from '@/components/card';
import { flagForCountryCode, pricesForProduct, formatForCurrency, getFallbackImage } from '@/utils';
import numeral from 'numeral';

const Select = dynamic(() => import('@/components/select'), { ssr: false });
const Image = dynamic(() => import('@/components/image'), { ssr: false });

dayjs.extend(duration);

const Sidebar = styled.aside`
  margin-right: 1em;
  position: sticky;
  top: 5em;
  width: 33.33%;

  ${media.sm`
    margin-bottom: 1em;
    margin-right: 0;
    position: static;
    width: 100%;
  `}
`;

const BrandLogo = styled(Card)`
  ${media.sm`
    padding-bottom: 45%;
  `};
`;

const ProductOptions = styled(Select)`
  .select {
    &__control {
      background-color: ${({ theme }) => theme.colors.background.card};
      border: none;
      border-radius: 1.5em !important;
      box-shadow: ${({ theme }) => theme.shadows.primary} !important;
      height: 3em;
      padding-left: .75em;

      &--menu-is-open {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
`;

const Button = styled.span`
  border-radius: 2em;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-weight: 600;
  margin-bottom: 1em;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  transition: box-shadow 250ms;

  ${({ disabled, theme }) => css`
    background: ${disabled ? theme.colors.disabled : theme.colors.primary};
    box-shadow: ${disabled ? 'none' : theme.shadows.primary};
    color: ${disabled ? theme.colors.typography.disabled : 'white'};

    &:hover, &.Tappable-active {
      box-shadow: ${disabled ? 'none' : theme.shadows.hover};
    }
  `};
`;

const Content = styled.section`
  width: 66.66%;
  padding-left: 1em;

  ${media.sm`
    border-top: 1px solid rgba(0, 0, 0, .05);
    margin: 0;
    padding: 1em;
    width: calc(100% + 2em);
  `};

  ${({ theme }) => css`
    ${media.sm`
      background: ${theme.colors.background.card};
    `}
  `};
`;

const RadioOptions = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 50%;
  }

  + ${Button} {
    margin-top: 2em;
  }
`;

const RadioOption = styled.label`
  align-items: flex-start;
  border: 1px solid transparent;
  border-radius: .25em;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  margin-top: 1.5em;
  padding: 1em;
  text-align: left;

  input {
    appearance: none;
    background: white;
    border: 0em solid transparent;
    border-radius: 50%;
    box-shadow:
      ${props => props.theme.shadows.primary},
      0 0 .125em rgba(0, 0, 0, .125);
    box-sizing: border-box;
    flex-grow: 0;
    flex-shrink: 0;
    height: 1em;
    margin: .25em 1em 0 0;
    transition: border 250ms, box-shadow 250ms;
    width: 1em;
    will-change: border, box-shadow;

    &:checked {
      border: .25em solid ${props => props.theme.colors.primary};
    }

    &:focus{
      outline: none;
    }
  }

  &:hover input {
    box-shadow: ${props => props.theme.shadows.hover};
  }

  &.checked {
    background: ${props => props.theme.colors.primary}11;
    border-color: ${props => props.theme.colors.primary}33;
  }
`;

const BrandContainer = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 1em 0;

  h4 {
    margin: 0 0 .5em;
  }

  ${media.sm`
    align-items: center;
    flex-direction: column;
    justify-content: center;

    ${Sidebar}, ${Content} {
      margin-right: 0;
    }

    ${RadioOption} {
      flex-basis: 100%;
    }

    ${RadioOptions} + ${Button} {
      bottom: 1em;
      position: sticky;
      transform: translate3d(0, 0, 0);
      width: 100%;
    }
  `}
`;

const PurchaseButton = styled(Button)``;
PurchaseButton.displayName = 'PurchaseButton';

const BrandName = styled.h2`
  color: ${({ theme }) => theme.colors.typography.primary};
  white-space: normal;
  margin-top: 0;
`;

const TopupContainer = styled.div`
  position: relative;
  margin-bottom: 1em;
`;

const TopupInput = styled.input`
  appearance: none;
  background: ${({ theme: { colors } }) => colors.background.card};
  border: 1px solid rgba(0, 0, 0, .125);
  box-shadow: inset 0 .0625em .125em rgba(0, 0, 0, .125);
  box-sizing: border-box;
  border-radius: .25em;
  color: ${({ theme: { colors } }) => colors.typography.primary};
  display: block;
  font-size: 16px;
  line-height: 2.75em;
  outline: none;
  padding: 0 1em;
  width: 100%;

  &::placeholder {
    color: #ababab;
  }

  ${({ validAmount }) => !validAmount && css`
    border-color: ${colors.status.danger};
    box-shadow: inset 0 .0625em .125em rgba(0, 0, 0, .125), 0 0 0 .25em ${colors.status.danger}33;
  `};
`;

const Tabs = styled.div`
  display: flex;
`;

const SectionName = styled.div`
  border-bottom: 2px solid ${props => props.selected ? props.theme.colors.primary : 'transparent'};
  color: ${({ theme }) => theme.colors.typography.primary};
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-top: 1em;
  padding: 0.5em;
  text-align: left;
  text-transform: uppercase;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.typography.secondary};
  font-weight: 400;
  margin: 0;
  text-align: left;
  white-space: pre-line;
`;

const DeliveryType = styled(Description)`
  font-size: 0.85em;
`;

const TabContent = styled.div`
  display: ${props => props.$visible ? 'block' : 'none'};
  font-size: 0.85em;
`;

const Tag = styled.span`
  background: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25em;
  display: inline-block;
  font-size: 0.85em;
  margin-top: 0.5em;
  padding: 0.125em 0.5em;
  text-transform: uppercase;

  & + & {
    margin-left: 0.5em;
  }
`;

const SubHeader = styled(Description)`
  margin-top: 1em;
  font-weight: bold;
`;

const URL = styled.a`
  font-size: 0.8em;
  font-weight: 400;
  text-align: left;
  display: block;
`;

const RedemptionUrl = styled(URL)`
  margin-top: 1em;
`;

const TaxInfo = styled.div`
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.typography.secondary};
  font-weight: 400;
  text-align: left;
  margin-bottom: 1em;
`;

const SeoText = styled.p`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.typography.secondary};
  font-weight: 400;

  ${media.sm`
    font-size: 0.8em;
  `}
`;

const OutOfStockMessage = styled.p`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.typography.secondary};
  font-weight: 400;
  text-align: center;
  padding: 2em;
  color: #c00;
`;

const OptionsWrapper = styled.section`
  margin: 3em auto 4em;
  max-width: 30em;

  ${media.sm`
    margin: 1em 0;
  `};
`;


const OptionDescription = styled.div`
  font-size: 75%;
  line-height: 1.5;

  span {
    display: block;
    margin-top: .5em;
  }
`;

const OptionDescriptionSmall = styled.div`
  font-size: 75%;
`;

const CustomAmountContainer = styled(TopupContainer)`
  align-items: center;
  display: flex;
`;

CustomAmountContainer.displayName = 'CustomAmountContainer';

const CustomAmountSwitch = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  margin-bottom: 1em;
  text-decoration: underline;
  font-size: inherit;
  padding: 0;
`;

CustomAmountSwitch.displayName = 'CustomAmountSwitch';

const SliderContainer = styled.div`
  flex: 1;
  padding: 0 .5em;
  user-select: none;
`;

const PriceRange = styled.small`
  color: ${({ theme }) => theme.colors.typography.subtle};
  margin: 0.5em 0em;

  strong {
    margin: 0 .5em;
  }

  ${({ amount, increment, max, min }) => {
    let style = css``;

    if (amount < min) {
      style = css`
        ${style};

        .min {
          color: ${colors.status.danger};
        }
      `;
    }

    if (amount > max) {
      style = css`
        ${style};

        .max {
          color: ${colors.status.danger};
        }
      `;
    }

    if ((amount / 100) % increment !== 0) {
      style = css`
        ${style};

        .increment {
          color: ${colors.status.danger};
        }
      `;
    }

    return style;
  }}
`;

const CountryWarning = styled.p`
  color: ${({ theme }) => theme.colors.typography.primary};
  font-size: .85em;
  margin: 0 0 1em;

  span {
    display: inline-block;
    margin-right: 0.1em;
  }
`;

const sanitizeText = text => {
  if (text && typeof text === 'string') {
    return text.replace(/  +/ig, ' ');
  }
  return text;
}

const ImportantNotesHeader = styled(Description)`
  align-items: center;
  display: flex;
  font-weight: bold;
  margin-bottom: .75em !important;

  svg {
    margin-right: .5em;
  }
`;

const ImportantNotesContent = styled(Description)`
  font-size: .75em;
  font-weight: 300;
  line-height: 1.4;
  outline: none;

  ${media.sm`
    max-height: 3em;
    overflow: hidden;
    text-overflow: ellipsis;
    mask-image: linear-gradient(to top, transparent, white);
  `};

  strong {
    font-weight: 600;
  }
`;

const ReadMore = styled.small`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  display: none;
  margin-top: 1em;
  text-decoration: underline;

  ${media.sm`
    display: block;
  `};
`;

const ImportantNotes = styled.div`
  background-color: white;
  border: 1px solid #f2f2f2;
  border-left-color: ${props => props.color || props.theme.colors.status.warning};
  border-left-width: .25em;
  border-radius: .25em;
  margin: 1em 0 0;
  padding: 1em;

  &:focus {
    ${ImportantNotesContent} {
      max-height: unset;
      mask-image: none;
    }

    ${ReadMore} {
      display: none;
    }
  }

  ${({ expanded }) => expanded && css`
    ${ImportantNotesContent} {
      max-height: unset;
      mask-image: none;
    }

    ${ReadMore} {
      display: none;
    }
  `}
`;

const LoadingState = styled.div`
  height: 140px;
  position: relative;
`;

const BrandExtraInfo = styled.div`
  border-top: 1px solid rgba(0, 0, 0, .05);
  padding: 2em 0 0;
  line-height: 1.5;
`;

const BreadCrumbContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: .85em;
  margin: 0 0 1.5em;

  ${({ theme }) => css`
    color: ${theme.colors.typography.secondary};

    a, a:visited, a:active {
      color: ${theme.colors.typography.primary};
    }
  `};
`;

const BreadCrumb = ({ brand, isDapp, onGoBack }) => {
  if (isDapp) {
    return (
      <BreadCrumbContainer>
        <a href="#" onClick={onGoBack}>
          {brand.country.name}
        </a>
        <ChevronSmallRight size={20} />
        {brand.name}
      </BreadCrumbContainer>
    );
  }

  return (
    <BreadCrumbContainer>
      <Link href={`/buy/${brand.country.slug}`}>
        {brand.country.name}
      </Link>
      <ChevronSmallRight size={20} />
      {brand.name}
    </BreadCrumbContainer>
  );
};

class Brand extends Component {
  isCustomAmount = false;
  selectedTab = 'description';

  //TODO: Handle strings in here
  get validAmount () {
    if (!this.props.store.product) return false;

    const {
      amount,
      product: {
        maxPriceInCents,
        minPriceInCents,
        variablePrice,
        allowedPricesInCents,
        currency,
        quoteCurrency,
        increment
      }
    } = this.props.store;
    const value = parseInt(amount, 10);

    if (!variablePrice) {
      return (!!allowedPricesInCents && allowedPricesInCents.includes(value))
        || (minPriceInCents === value);
    } else {
      const currs = ['CAD', 'EUR', 'AUD', 'GBP'];
      let min = minPriceInCents;
      if (currency === quoteCurrency && currs.includes(currency)) {
        min = Math.max(minPriceInCents, 500);
      }
      return (
        !!value
        && typeof value === 'number'
        && !Number.isNaN(value)
        && ((minPriceInCents && value >= min) || !minPriceInCents)
        && ((maxPriceInCents && value <= maxPriceInCents) || !maxPriceInCents)
        && (
          numeral(increment).value() > 1
           ? numeral(amount).divide(100).value() % numeral(increment).value() === 0
           : true
        )
      );
    }
  }

  constructor() {
    super();
    this.amountInputRef = createRef();
    makeObservable(this, {
      selectedTab: observable,
      isCustomAmount: observable,
      validAmount: computed,
      selectTab: action,
      switchCustomAmount: action
    })
  }

  componentDidMount () {
    const dappProvider = typeof window !== 'undefined' && (window.valora || window.bidaliProvider);

    if (dappProvider && dappProvider.phoneNumber) {
      this.props.store.onChangeAccountNumber(`${dappProvider.phoneNumber}`);
    }
  }

  onSelectOption = option => {
    const { store } = this.props;
    const { product, value } = option;
    store.setProduct(product);
    store.setAmount(value);
  };

  onChangeCustomAmount = (amount) => {
    const { store } = this.props;
    const value = parseInt(amount, 10);

    if (!Number.isNaN(value)) {
      if (get(this, 'amountInputRef.current')) {
        this.amountInputRef.current.value = value;
      }

      store.setAmount(value * 100);
    } else {
      store.setAmount(0);
    }
  };

  //TODO: Handle strings in here
  setMinOrMax = e => {
    const { store } = this.props;
    const { product } = store;
    const value = parseInt(e.target.value, 10) * 100;
    const inputRef = get(this, 'amountInputRef.current');
    const increment = numeral(product.increment).value();

    if (product && product.minPriceInCents && value < product.minPriceInCents) {
      inputRef.value = product.minPriceInCents / 100;
      store.setAmount(product.minPriceInCents);
    } else if (product && product.maxPriceInCents && value > product.maxPriceInCents) {
      inputRef.value = product.maxPriceInCents / 100;
      store.setAmount(product.maxPriceInCents);
    } else if (increment > 1 && (value / 100) % increment !== 0) {
      const closestMultiple = Math.round(value / 100 / increment) * increment;
      store.setAmount(closestMultiple * 100);
    }
  };

  hasLongDescriptions = products => {
    if (!products || products.length === 1) return false;

    const averageDescriptionLength = Math.round(
      products
        .map(p => p.description ? p.description.length : null)
        .reduce((acc, cur) => acc + cur, 0) / products.length
    );

    return averageDescriptionLength > 20 ? true : false;
  };

  formatOptionLabel = (option, selectContext) => {
    const { brand, products } = this.props;
    const { product, isProduct } = option;

    if (option.value === -1) {
      return 'Custom Amount';
    }

    if(product.additionalInfo) {
      const additionalInfo = product.additionalInfo.find(info => numeral(info.value).value()*100 === numeral(option.value).value());
      if(additionalInfo) {
        if(selectContext.context === 'value') {
          return <div>
            <div>
              <strong>{`${formatForCurrency({ amount: option.value, currency: product.currency })}`}</strong>
            </div>
            <OptionDescriptionSmall>
              {additionalInfo.features.map((feature, index) => (
                <span key={feature.description}>
                  {feature.description}: {feature.value}
                  {index < additionalInfo.features.length - 1 && ', '}
                </span>
              ))}
            </OptionDescriptionSmall>
          </div>;
        }
        return <div>
          <strong>{`${formatForCurrency({ amount: option.value, currency: product.currency })}`}</strong>
          <OptionDescription>
            {additionalInfo.features.map(feature => <span key={feature.description}><strong>{feature.description}:</strong> {feature.value}</span>)}
          </OptionDescription>
          </div>;
      }
    }

    //TODO: Handle additionalInfo on the brand...find the item in the array that has a matching value
    // and show the `features` description / value
    if (isProduct) {
      if (selectContext.context === 'value' || (!product.description && !product.validityPeriodIso)) {
        if (product.name === brand.name) {
          return `${formatForCurrency({ amount: option.value, currency: product.currency })}`;
        }
        return product.name;
      }

      let label = product.name;//.replace(/.00$/, '');

      return (
        <div>
          <strong>{label}</strong>
          <OptionDescription>
            <span>{product.description}</span>
            <span>
              {product.validityPeriodIso &&
                <i>Valid for {dayjs.duration(product.validityPeriodIso).asDays()} days</i>
              }
            </span>
          </OptionDescription>
        </div>
      );
    }

    let label = `${product.name}`;

    // if (label.indexOf(`${product.currency} ${option.value / 100}`) === -1) {
    label = `${formatForCurrency({ amount: option.value, currency: product.currency })}`;

    if (product.name !== brand.name && products.length > 1) {
      label = `${label} - ${product.name}`;
    }
    // }

    return label;
  };

  switchCustomAmount = () => {
    const { products, store } = this.props;
    const [product] = products;
    //TODO: Handle strings in here
    const value = Math.max(product.minPriceInCents, 500);
    this.isCustomAmount = !this.isCustomAmount;

    if (this.isCustomAmount) {
      this.onSelectOption({ product, value });
    } else {
      store.setAmount(0);
    }
  };

  selectTab = (tabName) => {
    this.selectedTab = tabName;
  }

  purchaseNow = async () => {
    const { isDapp, router, store } = this.props;
    const { accountNumber, amount, brand, product } = store;

    const added = await store.purchaseNow(accountNumber, amount, brand, product);

    if (added && !isDapp) {
      router.push('/checkout');
    }

    if (isDapp) {
      store.cart.setShowDappCheckout(true);
    }
  };

  render () {
    const {
      brand,
      products,
      store,
      seoText,
      isDapp,
      // size,
      t
    } = this.props;

    const {
      amount,
      addToCart,
      // purchaseNow,
      accountNumber,
      onChangeAccountNumber,
      hasValidAccountNumber,
      openUrl
    } = store;
    const hasLongDescriptions = this.hasLongDescriptions(products);
    const productTypes = uniq(map(products, 'type'));
    const isGiftcard = productTypes.length === 1 && productTypes[0] === 'giftcard';
    const isTopup = productTypes.length === 1 && productTypes[0] === 'topup';
    const isESim = productTypes.length === 1 && productTypes[0] === 'esim';
    const isSingleProduct = products && products.length === 1;
    const allowsCustomAmount = isSingleProduct && products[0].variablePrice;
    let showCustomAmount = allowsCustomAmount && this.isCustomAmount;
    let prices = isSingleProduct && pricesForProduct(products[0]) ? pricesForProduct(products[0]) : [];
    let amountOptions = [];
    let selectedOption = null;

    //TODO: Handle strings in here
    const mapProduct = product => {
      let value = product.minPriceInCents;
      if (product.allowedPricesInCents && product.allowedPricesInCents.length === 1) {
        value = product.allowedPricesInCents[0];
      };
      return {
        value,
        product,
        isProduct: true
      }
    };

    if (!!brand) {
      if (prices.length > 0) {
        amountOptions = amountOptions.concat(prices.map(amount => ({ value: amount, product: products[0] })));
      } else if (products && products.length > 0) {
        amountOptions = products.map(mapProduct).filter(option => !!option.value);
        amountOptions = sortBy(amountOptions, 'value');
      }

      selectedOption = amountOptions.filter(opt => (store.product && (opt.product.id === store.product.id && opt.value === store.amount)))[0];
    }
    let deliveryType = null;
    let requiresAccountNumber = false;
    let accountNumberLabel = t('enterAccountNumber');

    let accountNumberInputType = 'text';
    const canPurchase = !!brand.products && brand.enabled && !brand.discontinued && !brand.thirdPartyDisabled && brand.country.enabled && !brand.isOutOfStock;
    if (products && products.length > 0) {
      if (products[0].deliveryType === 'email') {
        deliveryType = t('emailVoucher');
        if (isESim) {
          deliveryType = t('emailESim');
          //TODO: eSims - requiresAccountNumber ONLY if they are topping up
          // accountNumberLabel = t('enterICCID');
        }
      } else if (products[0].deliveryType === 'phone') {
        requiresAccountNumber = true;
        accountNumberInputType = 'tel';
        deliveryType = t('instantDelivery');
        accountNumberLabel = t('enterPrepaidNumber');
        if (isTopup) {
          deliveryType = t('instantDeliveryMinutes');
        }
      } else if (products[0].deliveryType === 'account') {
        requiresAccountNumber = true;
        deliveryType = t('instantDelivery');
      }
    }

    const allowCart = !!addToCart && isGiftcard;
    const showPurchaseNow = !!this.purchaseNow && !requiresAccountNumber && !allowCart;

    const hasDescription = !!brand.description || !!brand.websiteUrl || !!brand.redemptionInstructions || !!brand.redemptionInstructionsUrl;
    const hasRedemptionMethods = !!brand.redemptionMethods && brand.redemptionMethods.length > 0;
    const hasDisclaimer = brand.disclaimer && brand.disclaimer !== '';

    const formattedDescription = sanitizeText(brand.description);
    const formattedDisclaimer = sanitizeText(brand.disclaimer);
    const formattedRedemptionInstructions = sanitizeText(brand.redemptionInstructions);

    const handleClickURL = url => event => {
      const { onClickURL } = this.props;
      event.preventDefault();

      if (typeof onClickURL === 'function') {
        return onClickURL(url);
      }

      if (url.startsWith('http') && typeof window !== 'undefined') {
        return window.open(url, '_blank');
      }

      return window.open(url);
    }

    return (
      <BrandContainer>
        <Sidebar>
          <BrandLogo $backgroundColor={brand.backgroundColor}>
            <Image
              alt={brand.name}
              fallback={getFallbackImage(brand.type)}
              height={150}
              src={brand.imageUrl}
              unoptimized
              width={250} />
          </BrandLogo>
          {!!brand.importantNotes &&
            <ImportantNotes tabIndex="-1">
              <ImportantNotesHeader>
                <Warning color={colors.status.warning} size={16} />
                {t('important')}
              </ImportantNotesHeader>
              <ImportantNotesContent>{brand.importantNotes}</ImportantNotesContent>
              <ReadMore>{t('readMore')}</ReadMore>
            </ImportantNotes>
          }
          {['topup', 'data'].includes(brand.type) &&
            <>
              {brand.type === 'topup' &&
                <ImportantNotes tabIndex="-1" expanded>
                  <ImportantNotesHeader>
                    <Warning color={colors.status.warning} size={16} />
                    {t('important')}
                  </ImportantNotesHeader>
                  <ImportantNotesContent>
                    {t('prepaidWarning')}
                  </ImportantNotesContent>
                </ImportantNotes>
              }
              <ImportantNotes tabIndex="-1" expanded color={colors.status.danger}>
                <ImportantNotesHeader>
                  <New color={colors.status.danger} size={22} />
                  {t('mobileMoney')}
                </ImportantNotesHeader>
                <ImportantNotesContent>
                  {t.rich('notMobileMoney', { strong: children => <strong>{children}</strong> })}
                </ImportantNotesContent>
              </ImportantNotes>
            </>
          }
        </Sidebar>
        <Content>
          <BreadCrumb brand={brand} isDapp={this.props.isDapp} onGoBack={this.props.onGoBack} />
          <BrandName>{brand.name}{brand.type === 'giftcard' && ' gift card'}</BrandName>
          {seoText && !isDapp && <SeoText>{seoText}</SeoText>}
          {(brand.isOutOfStock || brand.thirdPartyDisabled) &&
            <OutOfStockMessage>{t('outOfStock')}</OutOfStockMessage>
          }
          <CountryWarning>
            <span>{flagForCountryCode(brand.country.id)}</span> {t('regionWarning', { country: brand.country.name })}. <strong>{t('salesAreFinal')}</strong>
          </CountryWarning>
          {!brand.products && <LoadingState>
            <Loader />
          </LoadingState>}
          {canPurchase &&
            <OptionsWrapper>
              {prices && !hasLongDescriptions && !showCustomAmount &&
                <div>
                  <ProductOptions
                    className="select"
                    aria-label={`${t('selectOption')}`}
                    isSearchable={false}
                    formatOptionLabel={this.formatOptionLabel}
                    options={amountOptions}
                    onChange={this.onSelectOption}
                    placeholder={`${t('selectOption')}`}
                    value={selectedOption} />
                  {allowsCustomAmount &&
                    t.rich('pickCustomAmount', {
                      switch: children => <CustomAmountSwitch onClick={this.switchCustomAmount}>{children}</CustomAmountSwitch>
                    })
                  }
                </div>
              }
              {showCustomAmount &&
                <div>
                  <CustomAmountContainer>
                    <SliderContainer>
                      <TopupInput
                        required
                        ref={this.amountInputRef}
                        type="number"
                        //TODO: Handle strings in here
                        min={Math.max(products[0].minPriceInCents / 100, 5)}
                        max={products[0].maxPriceInCents / 100}
                        onBlur={this.setMinOrMax}
                        onChange={e => this.onChangeCustomAmount(e.target.value)}
                        step={numeral(products[0].increment).value() <= 1 ? 1 : numeral(products[0].increment).value()}
                        validAmount={this.validAmount}
                        value={amount / 100} />
                      {/* <PriceSlider
                        min={Math.max(products[0].minPriceInCents / 100, 5)}
                        max={products[0].maxPriceInCents / 100}
                        onChange={this.onChangeCustomAmount}
                        value={amount / 100}
                        step={5}
                        tipFormatter={() => formatForCurrency({ amount, currency: products[0].currency })} /> */}
                      <PriceRange
                        amount={amount}
                        increment={numeral(products[0].increment).value()}
                        max={products[0].maxPriceInCents}
                        min={Math.max(products[0].minPriceInCents, 500)}
                      >
                        {/* //TODO: Handle strings in here */}
                        <span className="min">
                          <strong>{t('min')}:</strong> {formatForCurrency({ amount: Math.max(products[0].minPriceInCents, 500), currency: products[0].currency })}
                        </span>
                        <span className="max">
                          <strong>{t('max')}:</strong> {formatForCurrency({ amount: products[0].maxPriceInCents, currency: products[0].currency })}
                        </span>
                        {numeral(products[0].increment).value() > 1 &&
                          <span className="increment">
                            <strong>{t('multiplesOf')}:</strong> {formatForCurrency({ amount: numeral(products[0].increment).multiply(100).value(), currency: products[0].currency })}
                          </span>
                        }
                      </PriceRange>
                    </SliderContainer>

                  </CustomAmountContainer>
                  {t.rich('pickFixedAmount', {
                    switch: children => <CustomAmountSwitch onClick={this.switchCustomAmount}>{children}</CustomAmountSwitch>
                  })}
                </div>
              }
              {allowCart &&
                <Button disabled={!this.validAmount} onClick={this.validAmount ? addToCart : null}>
                  {t('addToCart')}
                </Button>
              }
              {(showPurchaseNow) &&
                <PurchaseButton disabled={!this.validAmount} onClick={this.validAmount ? this.purchaseNow : null}>
                  {t('purchase')}
                </PurchaseButton>
              }
              {requiresAccountNumber &&
                <div>
                  <h4>{accountNumberLabel}</h4>
                  <TopupContainer>
                    <TopupInput
                      required
                      placeholder={brand.exampleNumber || ''}
                      type={accountNumberInputType}
                      onChange={e => onChangeAccountNumber(e.target.value)}
                      value={accountNumber} />
                  </TopupContainer>
                </div>
              }
              {hasLongDescriptions &&
                <>
                  <h4>{t('selectOption')}</h4>
                  <RadioOptions>
                    {amountOptions.map(option => (
                      <RadioOption className={!!store.product && store.product.id === option.product.id ? 'checked' : ''} key={option.product.id}>
                        <input
                          checked={!!store.product && store.product.id === option.product.id}
                          value={option.product.id}
                          onChange={() => this.onSelectOption(option)}
                          type="radio" />
                        {this.formatOptionLabel(option, {})}
                      </RadioOption>
                    ))}
                  </RadioOptions>
                </>
              }
              {requiresAccountNumber &&
                <Button disabled={!this.validAmount || !hasValidAccountNumber} onClick={!this.validAmount || !hasValidAccountNumber ? null : this.purchaseNow}>
                  {t('purchase')}
                </Button>
              }
            </OptionsWrapper>
          }
          <BrandExtraInfo>
            {products && products.length > 0 && products[0].taxInfo &&
              <TaxInfo>
                {t('taxInfo', { rate: products[0].taxInfo.rate, name: products[0].taxInfo.name })}
              </TaxInfo>
            }
            {deliveryType && <DeliveryType>{deliveryType}</DeliveryType>}

            <Tabs>
              {hasDescription &&
                <SectionName className={this.selectedTab === 'description' ? 'selected' : ''} selected={this.selectedTab === 'description'} onClick={() => this.selectTab('description')}>
                  {t('detailsTab')}
                </SectionName>
              }
              {hasDisclaimer &&
                <SectionName className={this.selectedTab === 'disclaimer' ? 'selected' : ''} selected={this.selectedTab === 'disclaimer'} onClick={() => this.selectTab('disclaimer')}>
                  {t('termsTab')}
                </SectionName>}
            </Tabs>
            {hasDescription &&
              <TabContent $visible={this.selectedTab === 'description'}>
                {!!formattedDescription && <Description>{formattedDescription}</Description>}

                {!!brand.websiteUrl && <>
                  <SubHeader>{t('website')}</SubHeader>
                  <URL onClick={handleClickURL(brand.websiteUrl)} href="#" title={brand.name} rel="nofollow noopener" target="_blank">{brand.websiteUrl}</URL>
                </>}

                {hasRedemptionMethods && <>
                  <SubHeader>{t('redemptionMethods')}</SubHeader>
                  <Description>
                    {brand.redemptionMethods.map(method => (
                      <Tag key={method}>{method}</Tag>
                    ))}
                  </Description>
                </>}

                {(!!brand.redemptionInstructionsUrl || !!brand.redemptionInstructions) && <>
                  <SubHeader>{t('redemptionInstructions')}</SubHeader>
                  {!!formattedRedemptionInstructions && <Description>{formattedRedemptionInstructions}</Description>}
                  {!!brand.redemptionInstructionsUrl && <RedemptionUrl onClick={handleClickURL(brand.redemptionInstructionsUrl)} title={`${brand.name} redemption instructions`} href="#" rel="nofollow noopener" target="_blank">{brand.redemptionInstructionsUrl}</RedemptionUrl>}
                </>}
              </TabContent>
            }
            {hasDisclaimer &&
              <TabContent $visible={this.selectedTab === 'disclaimer'}>
                <Description>{formattedDisclaimer}</Description>
              </TabContent>
            }
          </BrandExtraInfo>
        </Content>
      </BrandContainer>
    );
  }
}

const BrandComponent = observer(Brand);

const HOC = props => {
  const t = useTranslations('brand');

  return <BrandComponent {...props} t={t} />
};

export default HOC;
