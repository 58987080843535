import numeral from 'numeral';
import countries from '@data/countries';
import categories from '@data/cats';
import config from '@/config';

const localAddresses = ['::1', '127.0.0.1', 'localhost', '::ffff:127.0.0.1'];

const baseUrl = config.commerceApiUrl;

export const countryfetcher = (...args) => fetch(...args).then(res => res.json()).then(result => result[0]);

export const lookupCountry = async ip => {
  const params = {};
  let countryId = 'US';

  console.log('Default country:', countryId);
  console.log('API URL:', baseUrl);

  try {
    if (!localAddresses.includes(ip)) {
      console.log('Looking up country for IP:', ip);
      params.query = { ip };
      const response = await fetch(`${baseUrl}/country-lookup?ip=${ip}`);
      const result = await response.json();
      console.log('Got a country from IP:');
      console.log(result);

      if (result.countryId) {
        countryId = result.countryId;
      }
    }
  } catch (error) {
    console.log('Error getting country from IP', error);
  }

  return countryId;
};

export const fetchBrandsInCategoryAndCountry = async ({ countryId, categoryId, $search, $limit }) => {
  // const query = {
  //   countryId: countryId,
  //   'categories[0]': categoryId,
  //   enabled: true,
  //   discontinued: false,
  //   thirdPartyDisabled: false,
  //   $limit: 12,
  //   paginate: false
  // };
  // const response = await app.service('brands').find({ query });
  // return response.data;
  const queryParams = {
    countryId: countryId,
    enabled: true,
    discontinued: false,
    thirdPartyDisabled: false,
    paginate: false
  }

  let url = `${baseUrl}/brands?${new URLSearchParams(queryParams)}`;

  if (categoryId) {
    url = `${url}&categories[0]=${categoryId}`;
  }

  if ($search) {
    url = `${url}&$search=${encodeURIComponent($search)}`;
  }

  if ($limit) {
    url = `${url}&$limit=${encodeURIComponent($limit)}`;
  }

  console.log(queryParams, url);
  const res = await fetch(url);
  const result = await res.json();
  return result.filter(brand => brand.products.length > 0 && brand.products[0].currencyEnabled);
}

export const searchBrands = async ({ countryId, $search }) => {
  const queryParams = { minimal: true };
  const otherParams = { minimal: true };

  if (countryId) {
    queryParams.countryId = countryId;
    otherParams['countryId[$nin][]'] = countryId;
  }

  if ($search) {
    queryParams.$search = encodeURIComponent($search);
    otherParams.$search = encodeURIComponent($search);
  }

  const url = `${baseUrl}/search?${new URLSearchParams(queryParams)}`;
  const res = await fetch(url);
  const results = await res.json();

  if ($search && countryId) {
    const otherUrl = `${baseUrl}/search?${new URLSearchParams(otherParams)}`;
    const otherRes = await fetch(otherUrl);
    const otherResults = await otherRes.json();

    return { results, otherResults };
  }

  return { results };
};

export const countryForSlug = async slug => {
  let country = countries.find(c => c.slug === slug);

  if (!country) {
    console.log('country not found, fetching');
    country = await countryfetcher(`${baseUrl}/countries?slug=${slug}`);
    console.log(country);
  }

  return country;
};

export const countryForId = async id => {
  let country = countries.find(c => c.id === id);

  if (!country) {
    console.log('country not found, fetching');
    country = await countryfetcher(`${baseUrl}/countries/${id}`);
    console.log(country);
  }

  return country;
};

export const categoryForSlug = async slug => {
  console.log(`categoryForSlug`, slug);
  let category = categories.find(c => c.slug === slug);

  return category;
};

export const fetchBrand = async ({ countrySlug, slug }) => {
  const country = await countryForSlug(countrySlug);

  const queryParams = {
    countryId: country.id,
    enabled: true,
    discontinued: false,
    thirdPartyDisabled: false,
    slug,
    paginate: false
  };

  const url = `${baseUrl}/brands?${new URLSearchParams(queryParams)}`;
  const res = await fetch(url);
  const brandsResult = await res.json();
  const brand = brandsResult.find(b => b.products.length > 0 && b.products[0].currencyEnabled);

  if (brand) {
    delete brand.categories;
  }

  return brand;
};

export const fetchBrandNew = async ([countrySlug, slug]) => {
  let country;

  try {
    country = await countryForSlug(countrySlug);

    if (!country) {
      console.log('COUNTRY DOES NOT EXIST', countrySlug);
      return null;
    }

    const queryParams = {
      countryId: country.id,
      enabled: true,
      // discontinued: false,
      thirdPartyDisabled: false,
      slug,
      paginate: false
    };

    const url = `${baseUrl}/brands?${new URLSearchParams(queryParams)}`;
    const res = await fetch(url);
    const brandsResult = await res.json();
    const filteredBrands = brandsResult.filter(b => b.products.length > 0 && b.products[0].currencyEnabled);
    let brand = filteredBrands.find(b => !b.discontinued) || filteredBrands[0];

    if (brand) {
      brand.isOutOfStock = brand.discontinued;
      brand.products.map(p => {
        if (p.minPriceInCents) {
          p.minPriceInCents = numeral(p.minPriceInCents).value();
        }
        if (p.maxPriceInCents) {
          p.maxPriceInCents = numeral(p.maxPriceInCents).value();
        }
      });

      delete brand.categories;
    }

    return brand;
  } catch (e) {
    console.log('Error fetching brand', countrySlug, slug, country);
    console.log(e);
    e.data = { countrySlug, slug };
    throw e;
  }
};
